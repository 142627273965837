import Highlight from './Highlight'
import Copy from './Copy'
import ClickOutSide from './ClickOutSide'

const RaysDirective = (Vue) => {
  Highlight(Vue);
  Copy(Vue);
  ClickOutSide(Vue);
};
export default RaysDirective;
