import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_a_config_provider = _resolveComponent("a-config-provider")!

  return (_openBlock(), _createBlock(_component_a_config_provider, { locale: _ctx.zh_CN }, {
    default: _withCtx(() => [
      (_ctx.isRouterAlive)
        ? (_openBlock(), _createBlock(_component_router_view, { key: 0 }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["locale"]))
}