import { createStore, Store, useStore as baseUseStore } from 'vuex';
import { InjectionKey } from "vue";
import userStore from './userStore';
import shaderToolStore from './shaderToolStore';
import enterpriseStore from './enterpriseStore';
import imageDetectStore from './imageDetectStore';
import reportStore, {IStateReport} from './reportStore';

export interface RootState {
  user: any;
  shaderTool: any;
  enterprise: any;
  imageDetect: any;
  report: IStateReport;
}

export const key: InjectionKey<Store<RootState>> = Symbol();

const store = createStore<RootState>({
  modules: {
    user: userStore,
    shaderTool: shaderToolStore,
    enterprise: enterpriseStore,
    imageDetect: imageDetectStore,
    report: reportStore,
  },
});

export function useStore() {
  return baseUseStore(key);
}

export default store;
