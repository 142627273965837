import IconExt from './IconExt.vue';

export default function installIconExt(app) {
  //定义全局组件
  app.component('svg-icon', IconExt);
  //获取当前svg目录下的所有svg文件
  const req = require.context('@/assets/svg', false, /\.svg$/);
  //解析
  const requireAll = (requireContext) => {
    return requireContext.keys().map(requireContext);
  };
  requireAll(req);
}
