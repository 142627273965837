import { createApp } from 'vue';
import App from './App.vue';
import route from './routes';
import store, { key } from './vuex/store';
import Axios from 'axios';
//配置antd
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';

import VueVirtualScroller from 'vue-virtual-scroller';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';

import 'perfect-scrollbar/css/perfect-scrollbar.css';

import installIconExt from '@/components/IconExt/index.js';

import 'highlight.js/styles/stackoverflow-light.css';
import hljs from 'highlight.js/lib/core';
import glsl from 'highlight.js/lib/languages/glsl';
import cpp from 'highlight.js/lib/languages/cpp';
import RaysDirective from '@/directive';

hljs.registerLanguage('glsl', glsl);
hljs.registerLanguage('cpp', cpp);

// createApp(App).mount('#app')
const app = createApp(App);
//挂载路由
app.use(route);
//挂载antd
app.use(Antd);
//挂载vuex
app.use(store, key);
app.mount('#app');
app.config.globalProperties.Axios = Axios; //this.Axios

installIconExt(app);
app.use(VueVirtualScroller);

app.use(RaysDirective);

// app.config.globalProperties.Axios=Axios;
