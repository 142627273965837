import Hljs from 'highlight.js';

const Highlight = (Vue) => {
  Vue.directive('highlight', function (el) {
    const blocks = el.querySelectorAll('pre code');
    blocks.forEach((block) => {
      // 在forEach中做一些操作
      if (block.innerHTML) {
        if (!el.querySelector('.pre-numbering')) {
          // 1.创建ul节点
          const ul = document.createElement('ul');
          // 2.根据换行符获取行数，根据获取的行数生成行号
          const rowCount = block.outerHTML.split('\n').length;
          for (let i = 1; i <= rowCount; i++) {
            //创建li节点，创建文本节点
            const li = document.createElement('li');
            const text = document.createTextNode(i.toString());
            //为li追加文本节点，将li加入ul
            li.appendChild(text);
            ul.appendChild(li);
          }
          // 3.给行号添加类名
          ul.className = 'pre-numbering';
          // 4.将ul节点加到 代码块
          block.parentNode.prepend(ul);
        }
        Hljs.highlightBlock(block);
      }
    });
  });
};
export default Highlight;