const imageDetectStore = {
  state() {
    //数据
    return {
      shaderCart: {
        cartStep: 1,
        phoneTypes: [],
        shaderActiveKey: 0,
        shaderSelectAll: {},
        shaderIndeterminate: {},
        selectedGoods: {},
        patActiveKey: 0,
        patOptions: [],
        selectedPats: [],
        patsOpen: false,
      },
      updateShaderCart: 0,
      allGoods: [],
      originalGoods: [],
    };
  },
  mutations: {
    //方法
    setShaderCartSelectAll(state, val) {
      state.shaderCart.shaderSelectAll = val;
    },
    setShaderCartInfo(state, info) {
      state.shaderCart = info;
    },
    setShaderCartAllGoods(state, goods) {
      state.allGoods = goods;
    },
    setShaderCartOriginalGoods(state, goods) {
      state.originalGoods = goods;
    },
    setShaderCartUpdate(state, time) {
      state.updateShaderCart = time;
    },
  },
  getters: {
    //计算属性
  },
  actions: {
    //执行mutations里面的方法  异步操作放在actions
  },
};

export default imageDetectStore;
