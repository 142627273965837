import storage from '@/models/storage';

const toolStore = {
  state() {
    //数据
    return {
      simplifyHome: {
        menuKey: ['2'],
      },
      currentPat: null,
      patList: [],
      patListInfo: {
        uploadTip: false,
      },
      currentShader: null,
      currentShaderSimplifyTask: null,
      shaderHome: null,
      shaderSimplify: {
        taskUpdate: 0,
        pageStat: 0, //0 空界面  1 有数据
      },
      screenTaskList: [],
      shaderScreen: {
        taskUpdate: 0,
        pageStat: 0, //0 空界面  1 有数据
      },
      currentShaderScreenTask: null,
    };
  },
  mutations: {
    //方法
    setSimplifyHomeInfo(state, obj) {
      state.simplifyHome = obj;
    },
    setCurrentPat(state, obj) {
      state.currentPat = obj;
    },
    setPatList(state, list) {
      state.patList = list;
    },
    setPatListItem(state, item) {
      for (let i = 0; i < state.patList.length; i++) {
        if (state.patList[i].pat_name == item.pat_name) {
          state.patList[i] = item;
          break;
        }
      }
      storage.set('raystuner_pat_list', state.patList);
    },
    setPatListInfo(state, obj) {
      state.patListInfo = obj;
    },
    setCurrentShader(state, obj) {
      state.currentShader = obj;
    },
    setShaderHomeInfo(state, obj) {
      state.shaderHome = obj;
    },
    setCurrentShaderSimplifyTask(state, obj) {
      state.currentShaderSimplifyTask = obj;
    },
    setShaderSimplifyPageStat(state, val) {
      state.shaderSimplify.pageStat = val || 0;
    },
    setShaderSimplifyTaskUpdate(state, val) {
      state.shaderSimplify.taskUpdate = val || 0;
    },
    setScreenTaskList(state, arr) {
      state.screenTaskList = arr || [];
    },
    setCurrentShaderScreenTask(state, obj) {
      state.currentShaderScreenTask = obj;
    },
    setShaderScreenTaskUpdate(state, val) {
      state.shaderScreen.taskUpdate = val || 0;
    },
    setShaderScreenPageStat(state, val) {
      state.shaderScreen.pageStat = val || 0;
    },
  },
  getters: {
    //计算属性
  },
  actions: {
    //执行mutations里面的方法  异步操作放在actions
  },
};

export default toolStore;
