import { createRouter, createWebHistory } from 'vue-router';
import { getUser } from '@/models/tunerStorage';

//配置路由
const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/', redirect: '/login' },
    { path: '/login', component: () => import('@/views/home/Login.vue') },
    // { path: '/reg', component:  ()=>import('@/views/home/Reg.vue')},
    { path: '/404', component: () => import('@/views/404.vue') },
  ],
});

const userRouter = [
  {
    path: '/mainhome',
    name: 'mainhome',
    component: () => import('@/views/pat-manager/MainHome.vue'),
    children: [
      { path: '', redirect: '/mainhome/simplifyhome' },
      {
        path: 'simplifyhome',
        component: () => import('@/views/pat-manager/SimplifyHome.vue'),
        children: [
          { path: '', redirect: '/mainhome/simplifyhome/pat' },
          {
            path: 'pat',
            component: () => import('@/views/pat-manager/Pat.vue'),
          },
          {
            path: 'shader',
            component: () => import('@/views/pat-manager/Shader.vue'),
          },
          {
            path: 'simplifytask',
            component: () => import('@/views/task/SimplifyTask.vue'),
          },
          {
            path: 'screentask',
            component: () => import('@/views/task/ScreenTask.vue'),
          },
          {
            path: 'imagequality',
            component: () => import('@/views/task/ImageQuality.vue'),
          },
          {
            path: 'optimizereport',
            component: () => import('@/views/shader-detect/OptimizeReport/index.vue'),
          },
        ],
      },
      {
        path: 'help',
        component: ()=>import('@/views/pat-manager/Help.vue'),
      }
    ],
  },
  {
    path: '/shaderhome',
    name: 'shaderhome',
    component: () => import('@/views/shader-tools/ShaderHome.vue'),
    children: [
      { path: '', redirect: '/shaderhome/simplify' },
      {
        path: 'simplify',
        component: () => import('@/views/shader-tools/SimplifyTool.vue'),
        children: [
          { path: '', redirect: '/shaderhome/simplify/view' },
          {
            path: 'view',
            component: () =>
              import('@/views/shader-tools/SimplifyToolView.vue'),
          },
          {
            path: 'create',
            component: () =>
              import('@/views/shader-tools/SimplifyToolCreate.vue'),
          },
        ],
      },
      {
        path: 'screen',
        component: () => import('@/views/shader-tools/ScreenTool.vue'),
      },
    ],
  },
  {
    path: '/accounthome',
    name: 'accounthome',
    component: () => import('@/views/account-setting/AccountHome.vue'),
    children: [
      { path: '', redirect: '/accounthome/accountset' },
      {
        path: 'accountset',
        component: () => import('@/views/account-setting/AccountSet.vue'),
      },
    ],
  },
  // 画质检测详情
  {
    path: '/imageQualityDetail/:pat/:task_id',
    name: 'imageQualityDetail',
    component: () =>
      import('@/views/shader-detect/ImageQualityDetail/index.vue'),
  },
];

const enterRouter = [
  {
    path: '/accounthome',
    name: 'accounthome',
    component: () => import('@/views/account-setting/AccountHome.vue'),
    children: [
      { path: '', redirect: '/accounthome/devicemanage' },
      {
        path: 'devicemanage',
        component: () => import('@/views/account-setting/DeviceManage.vue'),
      },
      {
        path: 'membermanage',
        component: () => import('@/views/account-setting/MemberManage.vue'),
      },
      {
        path: 'accountset',
        component: () => import('@/views/account-setting/AccountSet.vue'),
      },
    ],
  },
];

router.beforeEach((to, from, next) => {
  const $_info = getUser();
  // console.log(from, to, $_info, to.fullPath,router.hasRoute('mainhome'));
  // if (to.fullPath !== '/login' && to.fullPath !== '/reg' && !$_info ) {
  if (
    to.fullPath !== '/login' &&
    (!$_info || ($_info.role_id != '1' && $_info.role_id != '2'))
  ) {
    next({ path: '/login' });
  } else if ($_info && $_info.uid) {
    if (!router.hasRoute('accounthome')) {
      if ($_info.role_id == '1') {
        for (let i = 0; i < userRouter.length; i++) {
          router.addRoute(userRouter[i]);
        }
      } else if ($_info.role_id == '2') {
        for (let i = 0; i < enterRouter.length; i++) {
          router.addRoute(enterRouter[i]);
        }
      }
      next({ path: to.fullPath });
    } else if (to.fullPath == '/login' || to.fullPath == '/reg') {
      if ($_info.role_id == '1') {
        next({ path: '/mainhome' });
      } else if ($_info.role_id == '2') {
        next({ path: '/accounthome' });
      }
    } else if (
      (from.fullPath == '/login' && to.fullPath == '/mainhome/simplifyhome') ||
      (from.fullPath == '/404' && to.fullPath == '/mainhome/simplifyhome')
    ) {
      if ($_info.role_id == '2') {
        next({ path: '/accounthome' });
      } else next();
    } else if (to.matched.length === 0) {
      next({ path: '/404' });
    } else next();
  } else if (
    !$_info &&
    to.fullPath == '/login' &&
    router.hasRoute('accounthome')
  ) {
    router.removeRoute('accounthome');
    if (router.hasRoute('shaderhome')) {
      router.removeRoute('shaderhome');
      router.removeRoute('mainhome');
    }
    next();
  } else if (to.matched.length === 0) {
    next({ path: '/404' });
  } else next();
});

export default router;
