const userStore = {
  state() {
    //数据
    return {
      user_id: 0,
      userinfo: {},
      mainInfo: {
        subPageTitle: '',
      },
      userDeviceModel: [],
      mailUpdate: 0,
      username: '',
      api_url: 'http://api.book.me',
      key: '2',
    };
  },
  mutations: {
    //方法
    setKey(state, key) {
      // console.log(this)
      state.key = key;
    },
    setUserInfo(state, info) {
      state.userinfo = info;
    },
    setUserName(state, name) {
      state.username = name;
    },
    setMainInfo(state, info) {
      state.mainInfo = info;
    },
    setUserDeviceModel(state, list) {
      state.userDeviceModel = list;
    },
    setMailUpdateTime(state, time) {
      state.mailUpdate = time;
    },
  },
  getters: {
    //计算属性
  },
  actions: {
    //执行mutations里面的方法  异步操作放在actions
  },
};

export default userStore;
