
import { defineComponent, computed, onMounted, onBeforeUnmount } from 'vue';
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN';
import 'moment/locale/zh-cn';
import { initAllInfo } from '@/models/tunerStorage';
import store from '@/vuex/store';

export default defineComponent({
  name: 'App',
  setup() {
    const patListInfo = computed(
      () => store.state.shaderTool.patListInfo || {}
    );

    initAllInfo();

    const beforeunload = function (event) {
      // console.log('beforeunload', event);
      if (patListInfo.value.uploadTip) {
        event.preventDefault();
        event.returnValue = ''; //"beforeunload111？";
        return '';
      }
    };

    onMounted(() => {
      window.addEventListener('beforeunload', beforeunload);
    });
    onBeforeUnmount(() => {
      window.removeEventListener('beforeunload', beforeunload);
    });
  },
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isRouterAlive: true,
      zh_CN,
    };
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      let _this = this;
      this.$nextTick(function () {
        _this.isRouterAlive = true;
      });
    },
  },
});
