const Copy = (Vue) => {
  // const copyData = {};
  const DOMCOPYDATAKEY = '@@domCopyDataKey';
  const DOMCOPYCLASS = 'v__copy__dom'
  
  const _copy = (event) => {
    let _el = event.target;
    while(_el){
      if(_el.className.includes(DOMCOPYCLASS)){
        break;
      }
      _el = _el.parentNode;
    }
    // const _copyKey = _el.className.split(' ').find(val=>val.includes('v_copy__'))?.substr(8);
    // console.log(event,_el[DOMCOPYDATAKEY]);
    // console.log(_el.className, _el.className.split(' '), _copyKey, copyData);
    const input = document.createElement('input');
    // input.setAttribute('readonly', 'readonly');
    // input.setAttribute('value', copyData[_copyKey].value);
    // const copyCallback = copyData[_copyKey].callback;
    input.setAttribute('value', _el[DOMCOPYDATAKEY].value);
    const copyCallback = _el[DOMCOPYDATAKEY].callback;
    document.body.appendChild(input);
    input.select()//setSelectionRange(0, 9999);
    if (document.execCommand('copy')) {
      document.execCommand('copy',true);
      // console.log(event.target[DOMCOPYDATAKEY]);
      if(copyCallback) copyCallback(true);
    } else if (copyCallback) {
      copyCallback(false);
    }
    document.body.removeChild(input);
  };

  Vue.directive('copy',{
    mounted(el, binding) {
      // console.log('directive copy mounted', el, binding);
      // copyData[binding.value.key] = {
      //   value: binding.value.value,
      //   callback: binding.arg
      // }
      el[DOMCOPYDATAKEY] = {
        value: binding.value.value,
        callback: binding.arg
      };
      el.addEventListener('click', _copy);
    },
    updated(el, binding) {
      // console.log('directive copy updated', el, el[DOMCOPYDATAKEY], binding);
      // 实时更新最新的目标内容
      // copyData[binding.value.key].value = binding.value.value;
      el[DOMCOPYDATAKEY].value = binding.value.value;

    },
    unmounted(el) {
      el.removeEventListener('click', _copy);
      // delete copyData[binding.value.key];
      delete el[DOMCOPYDATAKEY];
    }
  });
}

export default Copy