import storage from '@/models/storage';
export type IStateReport = {
  /** 检索对象 */
  search: any;
  /** 当前包 */
  packageActiveKey: string;
};

const state = {
  search: {
  },
  packageActiveKey: '0'
} as IStateReport;

const getters = {
  // menuList(state: IStateUser) {
  //   return state.permission.filter((item) => item.type === '0');
  // },
};

const mutations = {
  setPackageActiveKey(state: any, key:string) {
    state.packageActiveKey = key || '0';
    storage.set('raystuner_report_package', key);
  },
};

const actions = {
 
  // setAuth({ commit }: any, payload: IResultLogin | null) {
  //   if (payload === null) {
  //     storageCache.remove('auth');
  //     storageCache.remove('rays_base_file_tree');
  //     storageCache.remove('rays_base_file_list');
  //     storageCache.remove('rays_base_curfolder');
  //   } else {
  //     storageCache.set('auth', payload);
  //   }
  //   commit('setAuth', payload);
  // },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
