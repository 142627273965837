import axios from 'axios';
import { message } from 'ant-design-vue';
import qs from 'qs';
const getBaseUrl = (type) => {
  const vueAppEnv = process.env.VUE_APP_ENV;
  const protocol = location.protocol;
  const onlineBase = location.protocol + '//' + location.hostname;
  let devOrTestBase;
  if (type == 'engine') {
    devOrTestBase =
      process.env[
        protocol == 'http:'
          ? 'VUE_APP_BASE_ENGINE_URL'
          : 'VUE_APP_BASE_ENGINE_PATH'
      ];
  } else {
    devOrTestBase =
      process.env[
        protocol == 'http:' ? 'VUE_APP_BASE_WEB_URL' : 'VUE_APP_BASE_WEB_PATH'
      ];
  }
  const base = vueAppEnv == 'online' ? onlineBase : devOrTestBase;
  console.log('base----------', base);
  return base;
};
const createService = (type, baseURL) => {
  const configObj = {
    baseURL,
    // timeout: 25000,
    headers: {
      'Content-Type':
        type == 'web'
          ? 'application/x-www-form-urlencoded'
          : 'application/json;charset=UTF-8',
    },
    transformRequest: [
      function (data = {}) {
        if (data instanceof FormData) {
          return data;
        } else {
          if (data.isFormData) {
            delete data.isFormData;
            const formData = new FormData();
            for (const key in data) {
              if (Object.hasOwnProperty.call(data, key)) {
                const element = data[key];
                formData.append(key, element);
              }
            }
            return formData;
          }
          const dataRes =
            type == 'web' ? qs.stringify(data) : JSON.stringify(data);
          return dataRes;
        }
      },
    ],
  };
  const instance = axios.create(configObj);

  // 添加请求拦截器
  instance.interceptors.request.use(
    (config) => {
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  // http response 拦截器
  instance.interceptors.response.use(
    (response) => {
      // config.returnRes字段是发送请求时配置在config里为了区分是否返回全部响应数据的标识
      if (response.config.returnRes) {
        return response;
      } else {
        return response.data;
      }
    },
    (error) => {
      // config.noMessage字段是发送请求时配置在config里为了区分是否统一弹出异常错误提示的标识
      if (error.config && !error.config.noMessage) {
        message.error('服务器异常，请稍后再试呦~');
      }
      // return Promise.reject(error);
      return false;
    }
  );
  return instance;
};

const webServiceApi = createService('web', getBaseUrl('web'));
const engineServiceApi = createService('engine', getBaseUrl('engine'));
export { getBaseUrl, webServiceApi, engineServiceApi };
