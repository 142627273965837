import AES from 'crypto-js/aes';
import encUtf8 from 'crypto-js/enc-utf8';
const storage = {
  set(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  },
  get(key) {
    return JSON.parse(localStorage.getItem(key));
  },
  setCryp(key, value, tk) {
    let _times = this.get('rays_times') || {};
    _times[tk] = new Date().getTime();
    this.set('rays_times', _times);
    localStorage.setItem(
      key,
      AES.encrypt(
        JSON.stringify(value),
        'rays_' + _times[tk] + '_secret7676'
      ).toString()
    );
  },
  getCryp(key, tk) {
    let _times = this.get('rays_times');
    if (!_times) return '';

    return JSON.parse(
      AES.decrypt(
        localStorage.getItem(key),
        'rays_' + _times[tk] + '_secret7676'
      ).toString(encUtf8)
    );
  },
  remove(key) {
    localStorage.removeItem(key);
  },
};

export default storage;
