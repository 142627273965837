/**
 *  浏览器Storage 和 vuex的一些存储方法的封装
 *  tunerStorage.js  - v1.0.0
 */
import storage from '@/models/storage';
import store from '@/vuex/store';
import qs from 'qs';
import axios from 'axios';
import router from '@/routes';
import { getBaseUrl } from '@/api/service';
const weburl = getBaseUrl('web');

export function setUser(userinfo) {
  // storage.set('raystuner_user_info', userinfo);
  storage.setCryp('raystuner_user_info', userinfo, 'ru');
  store.commit('setUserInfo', userinfo);
}

export function getUser() {
  return storage.getCryp('raystuner_user_info', 'ru');
}

export function setMainInfo(title) {
  storage.set('raystuner_main_home', {
    subPageTitle: title,
  });
  store.commit('setMainInfo', {
    subPageTitle: title,
  });
}

export function setSimplifyHomeInfo(info) {
  storage.set('raystuner_simplify_home', info);
  store.commit('setSimplifyHomeInfo', info);
}

export function setPat(pat) {
  storage.set('raystuner_pat', pat);
  store.commit('setCurrentPat', pat);
}

export function setPatList(patlist) {
  storage.set('raystuner_pat_list', patlist);
  store.commit('setPatList', patlist);
}

export function setPatDeviceType(type) {
  const $_pat = storage.get('raystuner_pat');
  $_pat.deviceType = type;
  storage.set('raystuner_pat', $_pat);
  store.commit('setCurrentPat', $_pat);
}

export function setShader(shader) {
  storage.set('raystuner_shader', shader);
  store.commit('setCurrentShader', shader);
}

export function setShaderHomeInfo(home) {
  storage.set('raystuner_shader_home', home);
  store.commit('setShaderHomeInfo', home);
}

export function setShaderSimplify(task) {
  storage.set('raystuner_shader_simplify', task);
  store.commit('setCurrentShaderSimplifyTask', task);
}

export function setShaderScreen(task) {
  storage.set('raystuner_shader_screen', task);
  store.commit('setCurrentShaderScreenTask', task);
}

export function setShaderCartInfo(info) {
  storage.set('raystuner_shader_cart', info);
  store.commit('setShaderCartInfo', info);
}

export function setDeviceManageInfo(info) {
  storage.set('raystuner_device_manage', info);
  store.commit('setDeviceManageInfo', info);
}

export function setEnterpriseHomeInfo(info) {
  storage.set('raystuner_account_home', info);
  store.commit('setEnterpriseHomeInfo', info);
}

export function initAllInfo() {
  // const $_info = storage.get('raystuner_user_info');
  const $_info = storage.getCryp('raystuner_user_info', 'ru');
  store.commit('setUserInfo', $_info);

  const $_main = storage.get('raystuner_main_home') || {};
  store.commit('setMainInfo', $_main);
  const $_simplifyHome = storage.get('raystuner_simplify_home');
  store.commit('setSimplifyHomeInfo', $_simplifyHome);
  const $_pat = storage.get('raystuner_pat');
  store.commit('setCurrentPat', $_pat);
  const $_patlist = storage.get('raystuner_pat_list');
  store.commit('setPatList', $_patlist);
  const $_shader = storage.get('raystuner_shader');
  store.commit('setCurrentShader', $_shader);
  const $_shaderHome = storage.get('raystuner_shader_home');

  store.commit('setShaderHomeInfo', $_shaderHome);
  const $_shaderSimplify = storage.get('raystuner_shader_simplify');
  store.commit('setCurrentShaderSimplifyTask', $_shaderSimplify);
  const $_shaderScreen = storage.get('raystuner_shader_screen');
  store.commit('setCurrentShaderScreenTask', $_shaderScreen);

  const $_shaderCart = storage.get('raystuner_shader_cart');
  if ($_shaderCart) {
    store.commit('setShaderCartInfo', $_shaderCart);
  }

  const $_entermain = storage.get('raystuner_account_home') || {};
  store.commit('setEnterpriseHomeInfo', $_entermain);
  const $_deviceManage = storage.get('raystuner_device_manage') || {};
  store.commit('setDeviceManageInfo', $_deviceManage);

  const $_packageActiveKey = storage.get('raystuner_report_package') || '0';
  store.commit('report/setPackageActiveKey', $_packageActiveKey);
}

export const loginOut = () => {
  const $_info = storage.getCryp('raystuner_user_info', 'ru');
  if ($_info && $_info.uid) {
    axios
      .post(
        weburl + '/index.php/User/logout',
        qs.stringify({
          user_id: $_info.uid,
        })
      )
      .then(function (response) {
        // console.log('loginOut',response);
        if (response.data.code == 0) {
          console.log('服务器端退出登录成功！');
          removeAllInfo();
          router.push({
            path: '/login',
          });
        } else {
          console.error('服务器端退出登录失败：', response.data.msg);
        }
      })
      .catch(function (error) {
        console.error(error);
      });
  }
};

export function removeAllInfo() {
  storage.remove('raystuner_user_info');
  storage.remove('raystuner_main_home');
  storage.remove('raystuner_simplify_home');
  storage.remove('raystuner_pat');
  storage.remove('raystuner_pat_list');
  storage.remove('raystuner_shader');
  storage.remove('raystuner_shader_home');
  storage.remove('raystuner_shader_simplify');
  storage.remove('raystuner_shader_screen');
  storage.remove('raystuner_shader_cart');
  storage.remove('rays_times');
  storage.remove('raystuner_account_home');
  storage.remove('raystuner_device_manage');
  storage.remove('raystuner_report_package');
}

/**
 * 跳转到shader列表页面方法
 * @param {String} patName      Pat名称
 */
export function toShaderPage(patName) {
  let pat = {
    pat_name: patName,
  };
  storage.set('raystuner_pat', pat);
  store.commit('setCurrentPat', pat);
  router.push({
    path: '/mainhome/simplifyhome/shader',
  });
}

/**
 * 跳转到工作台-简化任务页面方法
 * @param {String} patName      Pat名称
 * @param {Number} shaderId     shader的ID
 * @param {String} taskNo       简化任务task_number
 */
export function toShaderSimplify(patName, shaderId, taskNo) {
  let pat = {
    pat_name: patName,
  };
  storage.set('raystuner_pat', pat);
  store.commit('setCurrentPat', pat);
  let shader = {
    shader_id: parseInt(shaderId) || 0,
  };
  storage.set('raystuner_shader', shader);
  store.commit('setCurrentShader', shader);
  let task = {
    task_number: taskNo,
    shader_id: parseInt(shaderId) || 0,
    params: {},
  };
  storage.set('raystuner_shader_simplify', task);
  store.commit('setCurrentShaderSimplifyTask', task);
  router.push({
    path: '/shaderhome/simplify/view',
  });
}

/**
 * 跳转到工作台-筛选任务页面方法
 * @param {String} patName          Pat名称
 * @param {Number} shaderId         shader的ID
 * @param {Number} simTaskId        简化任务id
 * @param {String} simTaskNo        简化任务task_number
 * @param {String} screenTaskNo     筛选任务task_number
 */
export function toShaderScreen(
  patName,
  shaderId,
  simTaskId,
  simTaskNo,
  screenTaskNo
) {
  let pat = {
    pat_name: patName,
  };
  storage.set('raystuner_pat', pat);
  store.commit('setCurrentPat', pat);
  let shader = {
    shader_id: parseInt(shaderId) || 0,
  };
  storage.set('raystuner_shader', shader);
  store.commit('setCurrentShader', shader);
  let simptask = {
    id: parseInt(simTaskId) || -1,
    task_number: simTaskNo,
    shader_id: parseInt(shaderId) || 0,
    params: {},
  };
  storage.set('raystuner_shader_simplify', simptask);
  store.commit('setCurrentShaderSimplifyTask', simptask);
  let screentask = {
    task_number: screenTaskNo,
    shader_id: parseInt(shaderId) || 0,
    params: {},
  };
  storage.set('raystuner_shader_screen', screentask);
  store.commit('setCurrentShaderScreenTask', screentask);
  const _homeinfo = storage.get('raystuner_shader_home') || {};
  _homeinfo.activeKey = '2';
  setShaderHomeInfo(_homeinfo);
  router.push({
    path: '/shaderhome/screen',
  });
}
