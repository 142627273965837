const ClickOutSide = (Vue) => {
  const outsideData = new Map();
  
  document.addEventListener('click', event => {
    // console.log('clickoutside', event);
    outsideData.forEach((item, el)=>{
      if (el instanceof Element){
        // console.log(el.contains(event.target as Node), outsideData);
        !el.contains(event.target as Node) && item(event);
      } else {
        item.els.every(_el=>{
          // console.log(_el);
          return !_el.contains(event.target as Node)
        }) && item.fun(event);
      }
    })
  });

  Vue.directive('clickoutside',{
    mounted(el, binding) {
      // console.log('directive clickoutside mounted', el, binding, outsideData);
      if (binding.arg) {
        const _tmp = outsideData.get(binding.arg) || {fun: null, els:[]};
        
        if (binding.value) {
          _tmp.fun = binding.value;
        }
        _tmp.els.push(el)
        outsideData.set(binding.arg, _tmp);
      } else {
        outsideData.set(el, binding.value);
      }
    },
    unmounted(el, binding) {
      if (binding.arg) {
        binding.value && outsideData.delete(binding.arg);
      } else {
        outsideData.delete(el);
      }
    }
  });
}

export default ClickOutSide