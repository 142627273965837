const enterpriseStore = {
  state() {
    //数据
    return {
      home: {
        menuKey: ['1'],
      },
      deviceManageInfo: {
        tabKey: '1',
      },
    };
  },
  mutations: {
    //方法
    setEnterpriseHomeInfo(state, obj) {
      state.home = obj;
    },
    setDeviceManageInfo(state, obj) {
      state.deviceManageInfo = obj;
    },
  },
  getters: {
    //计算属性
  },
  actions: {
    //执行mutations里面的方法  异步操作放在actions
  },
};

export default enterpriseStore;
